import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GreenButton } from "@components/GreenButton";
import SEO from "@components/SEO";

import styles from "@cssmodules/contact.module.scss";

export default function Contact() {
    useEffect(() => {
        const isDevEnv = process.env.NODE_ENV === "development";

        if (typeof document !== "undefined" && !isDevEnv) {
            document.body.style.background =
                "linear-gradient(#89c6bd, #a5d3c9, #d3dccf, #487275) no-repeat center center fixed";
            document.body.style.backgroundSize = "cover";
        }

        // Track conversion
        if (typeof window !== "undefined") {
            window.gtag("event", "conversion", {
                "send_to": "AW-518717093/1Fh4CMC44uMBEKX9q_cB",
            });
        }

        return () => {
            document.body.style.background = "";
            document.body.style.backgroundSize = "";
        };
    });

    return (
        <div className={styles.container}>
            <SEO title="Terima kasih!" />
            <Container fluid className="h-100 py-5">
                <Row className="h-100 justify-content-center align-items-center">
                    <Col className="mb-4">
                        <Row className="justify-content-center align-items-end">
                            <Col xl={4}>
                                <Card className={styles.card}>
                                    <Card.Body>
                                        <h5 className={styles.info}>
                                            Silahkan klik tombol hijau dibawah
                                            ini untuk langsung chat dengan CS
                                            kami melalui WhatsApp
                                        </h5>

                                        <GreenButton
                                            onClick={() => {
                                                window.open(
                                                    "https://nanya.online/gudang-seragam-2",
                                                    "_blank"
                                                );
                                            }}
                                            className={styles.mb}
                                            block
                                        >
                                            <img
                                                src={require("../assets/images/icons/icon-07.svg")}
                                                className={styles.waIcon}
                                                alt="whatsapp"
                                            />
                                            WhatsApp
                                        </GreenButton>

                                        <h6 className={styles.info2}>
                                            Jika loading tombol diatas mengalami
                                            masalah atau loadingnya terlalu
                                            lama, silahkan menghubungi kami
                                            melalui nomer dibawah ini
                                        </h6>

                                        <h5 className={styles.phone}>
                                            (Devi) 0813-1420-3335
                                        </h5>
                                        <h5 className={styles.phone}>
                                            (Diana) 0822-1995-3500
                                        </h5>
                                        <h5 className={styles.phone}>
                                            (021) 775-7089
                                        </h5>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={8}>
                                <h5 className={styles.info3}>
                                    Klik peta di bawah ini untuk melihat
                                    petunjuk arah:
                                </h5>
                                <iframe
                                    title="Gudang Seragam - Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.961470099413!2d106.8135499148554!3d-6.398966695369942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e95f083f99fd%3A0xf75e552abbcc1785!2sGudang%20seragam!5e0!3m2!1sid!2sid!4v1600052427880!5m2!1sid!2sid"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    className={`${styles.maps} mb-md-5 mb-lg-0`}
                                ></iframe>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
